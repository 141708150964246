<template>
  <div class="container">
    <div class="box">
      <div class="mop-css-1 double-bounce">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
      <p class="txt">正在努力加载中~</p>
    </div>
  </div>
</template>

<script>
import {GetUserInfo } from "@/api/base";

export default {
  name: "Author",
  created() {
    this.getUser()
  },
  methods: {
    async getUser() {
      console.log('this.$route', this.$route);
      const code = this.$route.query.code
      if (this.$store.state.info.openId) {  //已经登录过后回退
        sessionStorage.removeItem('path')
        await this.$store.dispatch('SaveInfo', {})
        setTimeout(function () {
          //这个可以关闭安卓系统的手机
          document.addEventListener('WeixinJSBridgeReady', function () {
            WeixinJSBridge.call('closeWindow');
          }, false);
          //这个可以关闭ios系统的手机
          WeixinJSBridge.call('closeWindow');
        }, 1)
        return
      }
      if (!code) {
        this.jumpAuth()
      } else {
        //存在这个code参数，去获取用户信息
        await GetUserInfo({code}).then(res => {
          if (res.errcode === 200) {
            this.$store.dispatch('SaveInfo', res.row)
          } else {
            this.$store.dispatch('SaveInfo', {})
            window.location.reload();
          }
        })

        await this.$router.push(sessionStorage.path || '/')
      }
    },
    jumpAuth(){
      let appId = 'wx2fa1fe519d60f86c';
      let NowUrl = encodeURIComponent(window.location.href.split('#')[0])
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + NowUrl + '&response_type=code&connect_redirect=1&scope=snsapi_userinfo&state=STATE#wechat_redirect';
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 35vh);
  .double-bounce{
    min-width: 60px;
    min-height: 60px;
    position: relative;
    .double-bounce1, .double-bounce2{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #67CF22;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: bounce 2.0s infinite ease-in-out;
      -moz-animation: bounce 2.0s infinite ease-in-out;
      -o-animation: bounce 2.0s infinite ease-in-out;
      animation: bounce 2.0s infinite ease-in-out;
    }
    .double-bounce2 {
      -webkit-animation-delay: -1.0s;
      -moz-animation-delay: -1.0s;
      -o-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }

    @-webkit-keyframes bounce {
      0%, 100% { -webkit-transform: scale(0.0) }
      50% { -webkit-transform: scale(1.0) }
    }
    @-moz-keyframes bounce {
      0%, 100% { -moz-transform: scale(0.0) }
      50% { -moz-transform: scale(1.0) }
    }
    @-o-keyframes bounce {
      0%, 100% { -o-transform: scale(0.0) }
      50% { -o-transform: scale(1.0) }
    }
    @keyframes bounce {
      0%, 100% {
        transform: scale(0.0);
      } 50% {
          transform: scale(1.0);
        }
    }
  }
  .txt{
    font-size: 14px;
    padding-top: 10px;
    opacity: 0.95;
  }
}
</style>
